import React from 'react';
import { Link } from 'react-router-dom';
import img from '../images/illustrations/undraw_revenue.svg';


const Cta = () => {
    return (
        <div className='cta'>
            <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6 px-10" id='cta' >
                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    <div className="w-full flex flex-col lg:flex-row lg:justify-around">
                        <div className="mb-4">
                            <p className='text-2xl md:text-4xl font-bold mb-4'>Are you ready to take your business to the next level?</p>
                            <p className="text-lg md:text-2xl">Get in touch and let us build something amazing <span className='font-black'>together!</span></p>
                            <div className="w-full lg:w-72 pt-6 lg:mx-12">
                                <Link to="/contact" className="text-skin-base bg-skin-button-base hover:bg-skin-button-base-hover inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">Send a message
                                    <svg className="w-5 h-5 ml-1 group-hover:translate-x-2 duration-500 ease-in" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </Link>
                            </div>
                        </div>
                        <div className="lg:w-1/3 w-1/2 flex lg:flex-col flex-row lg:mx-4 lg:justify-center self-center">
                            <img alt="card img" className="rounded-t float-right" src={img} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cta;