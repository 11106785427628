import React from 'react';
import img from '../images/illustrations/undraw_landing_page.svg';
import img2 from '../images/illustrations/undraw_website.svg';
import img3 from '../images/illustrations/undraw_secure_server.svg';
import img4 from '../images/illustrations/undraw_engineering_team.svg';
import { Link } from 'react-router-dom';

const Services = () => {
    return (
        <div id="services" className="bg-gray-100 py-12 px-4 lg:target:scroll-mt-24 target:scroll-mt-12" >
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-skin-inverted uppercase font-bold">services</h2>

                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-skin-base'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-skin-inverted">We are deeply committed to the growth and success of our clients.</h2>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="300">
                    <div className="grid sm:grid-cols-1 lg:grid-cols-4 gap-5">
                        <div className="lg:col-start-2 sm:col-start-1 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-skin-accent-light rounded-lg shadow-2xl p-5 group">
                            <div className="m-2 text-justify text-sm flex flex-col h-full">
                                <img alt="card img" className="grow lg:w-full w-1/2 self-center rounded-t group-hover:scale-[1.15] transition duration-500 ease-in-out" src={img} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Web Development</h2>
                                <p className="text-md font-medium lg:px-0 sm:px-8 pb-4">
                                    We specialize in creating and optimizing high-quality, custom websites for businesses and organizations of all sizes. Building mobile-friendly and easy-to-use websites and applications for clients.
                                </p>
                                <Link to="/web-development" className="self-center text-skin-base bg-skin-button-base hover:bg-skin-button-base-hover inline-flex items-center justify-center w-1/2 py-1 mb-2 text-lg shadow-xl rounded-2xl">
                                    More
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </Link>
                            </div>
                        </div>

                        <div className="bg-skin-base transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-skin-accent-light rounded-lg shadow-2xl p-5 group">
                            <div className="m-2 text-justify text-sm flex flex-col h-full">
                                <img alt="card img" className="grow lg:w-full w-1/2 self-center rounded-t group-hover:scale-[1.15] transition duration-500 ease-in-out" src={img4} />
                                <h2 className="font-semibold my-4 text-2xl text-center ">General IT Consultations</h2>
                                <p className="text-md font-medium lg:px-0 sm:px-8 pb-4">
                                    Our IT consultations service provides expert guidance and support for businesses and individuals looking to optimize their technology solutions.
                                </p>
                                <Link to="/contact" className="self-center text-skin-base bg-skin-button-base hover:bg-skin-button-base-hover inline-flex items-center justify-center w-1/2 py-1 mb-2 text-lg shadow-xl rounded-2xl">
                                    More
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center px-8">
                            <div className='text-skin-inverted mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path><path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path></svg>
                            </div>
                            <h3 className="text-3xl  text-skin-inverted 
                            font-bold">We <span className='font-black'>Build</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                    Having accumulated more than a decade of expertise in software analysis and design, coupled with a profound comprehension of contemporary IT trends and solutions, we deliver tailored suggestions and tactics to enhance your operations, minimize expenses, and boost efficiency.
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center px-8">
                            <div className='text-skin-inverted mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path></svg>
                            </div>
                            <h3 className="text-3xl  text-skin-inverted font-bold">We <span className='font-black'>Collaborate</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>We're able to partner with your current tech team to expand existing software applications or create personalized software solutions tailored to your daily requirements, streamlining various operations in the process.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services;