import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">
                    {/* Top area: Blocks */}
                    <div className="grid grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200">
                        {/* 2nd block */}
                        <div className="col-start-2 col-span-5 mx-auto">
                            <h6 className="text-skin-inverted text-xl font-bold mb-4">LINKS</h6>
                            <ul className="text-md">
                                <li className="mb-2">
                                    <HashLink to="/#about" className="text-skin-inverted hover:text-gray-900 transition duration-250 ease-in-out">About</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="/#services" className="text-skin-inverted hover:text-gray-900 transition duration-250 ease-in-out">Services</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="/contact#top" className="text-skin-inverted hover:text-gray-900 transition duration-250 ease-in-out">Contact</HashLink>
                                </li>
                            </ul>
                        </div>

                        {/* 3rd block */}
                        <div className="col-span-5 mx-auto">
                            <h6 className="text-skin-inverted text-xl font-bold mb-4">OUR SERVICES</h6>
                            <ul className="text-sm md:text-md">
                                <li className="mb-2">
                                    <Link to="/web-development" className="text-skin-inverted hover:text-gray-900 transition duration-250 ease-in-out">Web Development</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="/contact" className="text-skin-inverted hover:text-gray-900 transition duration-250 ease-in-out">General IT Consultations</Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
                        <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                            <div className="text-sm text-skin-inverted font-semibold py-1">
                                Copyright &copy; {new Date().getFullYear()}{"  "}
                                <HashLink
                                    to="#top"
                                    className=" hover:text-gray-900"
                                >
                                    Web Works Creators
                                </HashLink>. All rights reserved.
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;
