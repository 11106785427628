import React, { useState, useRef } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import axios from 'axios';
// import emailjs from 'emailjs-com';
import Notiflix from 'notiflix';
import ReCAPTCHA from 'react-google-recaptcha'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import usePageTracking from '../usePageTracking';

const Contact = () => {
    usePageTracking();

    useDocTitle('EWS | Easy Web Solutions - Send us a message')
    const recaptcha = useRef()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState([])
    const [isSubmitted, setIsSubmitted] = useState(false);


    const clearErrors = () => {
        setErrors([])
    }

    const clearInput = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setMessage('')
        recaptcha.current.reset()
    }

    const sendEmail = (e) => {
        e.preventDefault();
        const captchaValue = recaptcha.current.getValue()
        if (!captchaValue) {
            Notiflix.Report.failure(
                'An error occurred',
                'Please verify the reCAPTCHA!',
                'Okay',
            );
        } else if (firstName && lastName && email && phone && message) {
            document.getElementById('submitBtn').disabled = true;
            document.getElementById('submitBtn').innerHTML = 'Loading...';
            let fData = new FormData();
            fData.append('first_name', firstName)
            fData.append('last_name', lastName)
            fData.append('email', email)
            fData.append('phone_number', phone)
            fData.append('message', message)

            axios({
                method: "post",
                url: process.env.REACT_APP_CONTACT_API,
                data: JSON.stringify({ firstName: firstName, lastName: lastName, email: email, phone_number: phone, message: message }),
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then(function (response) {
                    if (response.data.success) {
                        document.getElementById('submitBtn').disabled = false;
                        document.getElementById('submitBtn').innerHTML = 'send message';
                        clearInput()
                        //handle success
                        setIsSubmitted(true);
                        Notiflix.Report.success(
                            'Success',
                            response.data.message,
                            'Okay',
                        );
                    }
                    else {
                        document.getElementById('submitBtn').disabled = false;
                        document.getElementById('submitBtn').innerHTML = 'send message';
                        //handle error
                        Notiflix.Report.failure(
                            'An error occurred',
                            'An error occurred while sending your message. Please try again later.',
                            'Okay',
                        );
                    }
                })
                .catch(function (error) {
                    document.getElementById('submitBtn').disabled = false;
                    document.getElementById('submitBtn').innerHTML = 'send message';
                    //handle error
                    const { response } = error;
                    if (response.status === 500) {
                        Notiflix.Report.failure(
                            'An error occurred',
                            response.data.message,
                            'Okay',
                        );
                    }
                    if (response.data.errors !== null) {
                        setErrors(response.data.errors)
                    }

                });
        }
        else {
            let errors = {};
            if (!firstName) errors.first_name = 'First Name is required'
            if (!lastName) errors.last_name = 'Last Name is required'
            if (!email) errors.email = 'Email is required'
            if (!phone) errors.phone_number = 'Phone Number is required'
            if (!message) errors.message = 'Message is required'
            setErrors(errors)
        }
    }
    return (
        <>
            <div>
                <NavBar />
            </div>
            <div id='contact' className="flex justify-center items-center w-full py-12 lg:py-24 ">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
                    <form onSubmit={sendEmail}>

                        <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                            <div className="flex">
                                <h1 className="font-bold text-center lg:text-left text-skin-inverted uppercase text-4xl">Send us a message</h1>
                            </div>
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                <div>
                                    <input
                                        name="first_name"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text"
                                        placeholder="First Name*"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors &&
                                        <p className="text-red-500 text-sm">{errors.first_name}</p>
                                    }
                                </div>

                                <div>
                                    <input
                                        name="last_name"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text"
                                        placeholder="Last Name*"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors &&
                                        <p className="text-red-500 text-sm">{errors.last_name}</p>
                                    }
                                </div>

                                <div>
                                    <input
                                        name="email"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="email"
                                        placeholder="Email*"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors &&
                                        <p className="text-red-500 text-sm">{errors.email}</p>
                                    }
                                </div>

                                <div>
                                    <input
                                        name="phone_number"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="number"
                                        placeholder="Phone*"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors &&
                                        <p className="text-red-500 text-sm">{errors.phone_number}</p>
                                    }
                                </div>
                            </div>
                            <div className="my-4">
                                <textarea
                                    name="message"
                                    placeholder="Message*"
                                    className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onKeyUp={clearErrors}
                                ></textarea>
                                {errors &&
                                    <p className="text-red-500 text-sm">{errors.message}</p>
                                }
                            </div>
                            <div className="my-4">
                                <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                            </div>
                            <div className="my-2 md:w-1/2 w-full">
                                <button aria-label="Send Message" type="submit" id="submitBtn" className="uppercase text-sm font-bold tracking-wide bg-skin-button-base hover:bg-skin-button-base-hover text-gray-100 p-3 rounded-lg w-full 
                                    focus:outline-none focus:shadow-outline">
                                    Send Message
                                </button>
                            </div>
                        </div>

                    </form>
                    <div
                        className="w-full lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-skin-accent-base rounded-2xl">
                        <div className="flex flex-col text-skin-base">

                            <div className="flex my-4 w-2/3 lg:w-3/4">
                                <div className="flex flex-col justify-center">
                                    <FontAwesomeIcon icon={faMapMarker} />

                                </div>
                                <div className="flex flex-col pl-2">
                                    <h2 className="text-2xl">Office Address</h2>
                                    <p className="text-skin-muted">Chicago, Illinois.</p>
                                </div>
                            </div>
                            <div className="flex my-4 w-2/3 lg:w-3/4">
                                <div className="flex flex-col justify-center">
                                    <FontAwesomeIcon icon={faPhone} />

                                </div>
                                <div className="flex flex-col pl-2">
                                    <h2 className="text-2xl">Call Us</h2>
                                    <p className="text-skin-muted">Tel: +1 (773) 270-2068</p>
                                </div>
                            </div>
                            <div className="flex my-4 w-2/3 lg:w-1/2">
                                <div className="flex flex-col justify-center">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </div>
                                <div className="flex flex-col pl-2">
                                    <h2 className="text-2xl">Send an E-mail</h2>
                                    <p className="text-skin-muted">info@webworkscreators.com</p>

                                </div>
                            </div>

                            {/* <div className="flex my-4 w-2/3 lg:w-1/2">
                                <a href="https://www.facebook.com/ENLIGHTENEERING/" target="_blank" rel="noreferrer" className="rounded-full flex justify-center bg-skin-base h-8 text-skin-inverted  w-8  mx-1 text-center pt-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='fill-current font-black hover:animate-pulse'><path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path></svg>
                                </a>
                                <a href="https://www.linkedin.com/company/enlighteneering-inc-" target="_blank" rel="noreferrer" className="rounded-full flex justify-center bg-skin-base h-8 text-skin-inverted  w-8  mx-1 text-center pt-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='fill-current font-black hover:animate-pulse'><circle cx="4.983" cy="5.009" r="2.188"></circle><path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"></path></svg>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>


    )
}

export default Contact;