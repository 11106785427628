import React from 'react';
import img from '../images/illustrations/undraw_programming.svg';
// import img from '../images/Web-developer.svg';
import { Link } from 'react-router-dom';

const Intro = () => {
    return (
        <div id='about' className='px-10 lg:target:scroll-mt-24 target:scroll-mt-12' >
            <div className="m-auto max-w-6xl md:p-12 h-5/6">
                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    <div className="lg:w-1/2 w-1/2 flex flex-col lg:mx-4 justify-center self-center">
                        <img alt="card img" className="rounded-t float-right" src={img} />
                    </div>
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="300">
                        <h3 className="text-3xl text-skin-inverted font-bold">We specialize in crafting high-quality custom websites for organizations, institutions, and SMBs</h3>
                        <div>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>Our proficient team excels in software development and stands ready to assist in creating the applications you desire.</p>
                        </div>

                        <div>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>We undertake the responsibility of constructing tailor-made software solutions that automate your business processes and enhance efficiency.</p>
                        </div>
                        <Link to="/contact" className="text-skin-base bg-skin-button-base hover:bg-skin-button-base-hover inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                            Contact us
                            <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Intro;