import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import axios from 'axios';
// import emailjs from 'emailjs-com';
import Notiflix from 'notiflix';
import usePageTracking from '../usePageTracking';
import img1 from '../images/illustrations/pricing/undraw_static_website.svg';
import img2 from '../images/illustrations/pricing/undraw_personal_website.svg';
import img3 from '../images/illustrations/pricing/undraw_web_devices.svg';
import Cta from '../components/Cta';


const WebDevelopment = (props) => {
    usePageTracking();
    useDocTitle('Web Works Creators - Demo our products')

    return (
        <>
            <div>
                <NavBar />
            </div>
            <div className="pricing py-12 lg:py-24" >
                <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-skin-inverted uppercase font-bold">pricing</h2>
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-skin-base'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-skin-inverted">We are deeply committed to the growth and success of our clients.</h2>
                    </div>
                    <div className="px-12" data-aos="fade-down" data-aos-delay="300">
                        <div className="grid sm:grid-cols-1 lg:grid-cols-5 gap-5">
                            <div className="lg:col-start-2 bg-skin-base transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-skin-accent-light rounded-lg shadow-2xl p-5 group">
                                <div className="m-2 text-sm flex flex-col h-full justify-between">
                                    <div>
                                        <h2 className="font-semibold mb-4 text-2xl text-center">Basic</h2>
                                        <img alt="card img" className="mx-auto self-center lg:w-full w-1/2 rounded-t group-hover:scale-[1.15] transition duration-500 ease-in-out" src={img1} />
                                    </div>
                                    <div className='flex flex-col'>
                                        <h1 className="self-center text-md font-medium lg:px-0 sm:px-8 pb-4 text-xl">
                                            Includes:
                                        </h1>
                                        <ul className="self-center list-disc text-md font-medium lg:px-0 sm:px-8 pb-4">
                                            <li>Single Page Web Application</li>
                                            <li>Customized design</li>
                                            <li>Responsive design</li>
                                            <li>SEO optimization</li>
                                            <li>1 year Hosting Services</li>
                                            <li>1 year Support Services [10 hours]</li>
                                            <li>Domain Registration (subject to surcharge)</li>
                                        </ul>
                                        <h2 className='self-center text-skin-base bg-skin-button-base group-hover:bg-skin-button-base-hover inline-flex items-center justify-center w-1/2 py-1 mb-2 text-lg shadow-xl rounded-2xl'>$1495</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-skin-base transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-skin-accent-light rounded-lg shadow-2xl p-5 group">
                                <div className="m-2 text-sm flex flex-col h-full  justify-between">
                                    <div>
                                        <h2 className="font-semibold mb-4 text-2xl text-center">Custom</h2>
                                        <img alt="card img" className="mx-auto lg:w-full w-1/2 self-center rounded-t group-hover:scale-[1.15] transition duration-500 ease-in-out" src={img2} />
                                    </div>
                                    <div className='flex flex-col'>
                                        <h1 className="self-center text-md font-medium lg:px-0 sm:px-8 pb-4 text-xl">
                                            Includes:
                                        </h1>
                                        <p className=' self-center text-md font-medium lg:px-0 sm:px-8 pb-4'> Same as BASIC + </p>
                                        <h1 className=" self-center text-md font-medium lg:px-0 sm:px-8 pb-4 text-xl">
                                            Optional:
                                        </h1>
                                        <ul className="self-center list-disc text-md font-medium lg:px-0 sm:px-8 pb-4">
                                            <li>Single/Multi Page Web Application</li>
                                            <li>Contact Form/Newsletter Subscription</li>
                                            <li>Dedicated Email Mailbox</li>
                                        </ul>
                                        <h2 className='mt-2 self-center text-skin-base bg-skin-button-base group-hover:bg-skin-button-base-hover inline-flex items-center justify-center w-1/2 py-1 mb-2 text-lg shadow-xl rounded-2xl'>$1695+</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-skin-base transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-skin-accent-light rounded-lg shadow-2xl p-5 group">
                                <div className="m-2 text-sm flex flex-col h-full justify-between">
                                    <div>
                                        <h2 className="font-semibold mb-4 text-2xl text-center">Pro</h2>
                                        <img alt="card img" className="mx-auto lg:w-full w-1/2 self-center rounded-t group-hover:scale-[1.15] transition duration-500 ease-in-out" src={img3} />
                                    </div>
                                    <div className='flex flex-col'>
                                        <h1 className="self-center text-md font-medium lg:px-0 sm:px-8 pb-4 text-xl">
                                            Includes:
                                        </h1>
                                        <ul className="self-center list-disc text-md font-medium lg:px-0 sm:px-8 pb-4">
                                            <li>Multiple Page Web Application</li>
                                            <li>Customized design</li>
                                            <li>Responsive design</li>
                                            <li>SEO optimization</li>
                                            <li>1 year Hosting Services</li>
                                            <li>1 year Support Services [10 hours]</li>
                                            <li>Domain Registration (subject to surcharge)</li>
                                            <li>Contact Form/Newsletter Subscription</li>
                                            <li>Dedicated Email Mailbox</li>
                                        </ul>
                                        <h2 className='self-center text-skin-base bg-skin-button-base group-hover:bg-skin-button-base-hover inline-flex items-center justify-center w-1/2 py-1 mb-2 text-lg shadow-xl rounded-2xl'>$2495+</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Cta />
            <Footer />
        </>


    )
}

export default WebDevelopment;