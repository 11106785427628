import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-skin-inverted" smooth to="/#about">
                About
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-skin-inverted" smooth to="/#services">
                Services
            </HashLink>
            <HashLink className="text-skin-base bg-skin-button-base hover:bg-skin-button-base-hover inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" smooth to="/contact#top">
                Contact Us
            </HashLink>
        </>
    )
}

export default NavLinks;
